import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';

import './Table.scss';
import ITable from '../interfaces/Table';
import {FormContext} from '../../../context/FormContext';
import TableRow from '../interfaces/TableRow';
import { table } from 'console';
import KeyReplace from '../../KeyReplace';

interface IProps {
  component:ITable;
}

const Table:FunctionComponent<IProps> = props => {
  
  const formContext = useContext(FormContext);
  
  let [rows, setRows] = useState<JSX.Element[]>([]);
  let [subTableKeys, setSubTableKeys] = useState<Map<string,JSX.Element[]>>(new Map());
  let [subTableArray, setSubTableArray] = useState<JSX.Element[][]>([]);

  function replaceText(text:string):string {
    let newString = text;
    if (formContext.formData) {
      Array.from(formContext.formData).forEach((value:[string,string]) => 
        {
         
          newString = newString.replaceAll("{" + value[0] + "}", value[1])
          
        }
      )
     //go through one more time incase there is a value that also needs replacing again
      Array.from(formContext.formData).forEach((value:[string,string]) => 
        {
          
          newString = newString.replaceAll("{" + value[0] + "}", value[1])
          
        }
      )
    }
    if(newString !== text) {
      newString = newString.replaceAll(/ *\{[^)]*\} */g, "");
    }
    return newString;

    
  }

  function getRows(subTableKey?:string) {
    let rows:JSX.Element[] = [];
    
    {props.component.rows.map((row:TableRow, index:number) => {
      let rowValue = row.value;
      if(subTableKey) {
        rowValue = rowValue.replaceAll('{','{' + subTableKey + '.');
      }
      //console.log(rowValue);
      let replacedText:string = replaceText(rowValue);
              if (replacedText !== row.value && replacedText !== '') {
                
                rows.push(
                  <tr key={props.component.title + 'row-' + index}>
                  <td><KeyReplace string={row.label} /></td>
                  <td>{replacedText}</td>
                  </tr>
                );
              } 
              
    }
    )}
    
    return rows;
  }

  


  function getSubTableKeys() {
    let subTableKeys:Map<string,JSX.Element[]> = new Map();
    let keys = formContext.formData?.keys();
    let dataSourceLength = props.component.dataSource?.length || 0;
    let newSubTableArray:JSX.Element[][] = [];
    if (keys && props.component.dataSource) {
      Array.from(keys).forEach((key:string) => {
        if (key.startsWith(props.component.dataSource || '')) {
          let modifiedKey = key.substr(dataSourceLength + 1).split('.')[0];
          subTableKeys.set(modifiedKey, []);
        }
      });
      Array.from(subTableKeys.keys()).forEach((key:string) => {
        let rows = getRows(props.component.dataSource + '.' + key);
        //console.log(rows);
        newSubTableArray.push(rows);
      });
    }
    
    return newSubTableArray;
  }

  React.useEffect(() => {
    if(props.component.dataSource) {
      setSubTableArray(getSubTableKeys());
      
    } else {
      setRows(getRows());
    }
   }, [formContext.formRevision]);


  return (
    <Fragment>
      {!props.component.dataSource &&
    <div style={{display : (rows.length > 0 ? 'block' : 'none')}} className="table">
        <h2><KeyReplace string={props.component.title} /></h2>
        <table>
            <tbody>
              {rows.map((row:JSX.Element) => row)}
            </tbody>
        </table>
    </div>}
    {props.component.dataSource &&
      <div>
        {subTableArray.map((subTable, index) => 
          <div className="table">
            <h2><KeyReplace string={props.component.subTitle || ''} count={index + 1} /></h2>
            <table>
            <tbody>
              {subTable.map((row:JSX.Element) => row)}
            </tbody>
            </table>
          </div>
        )}
      </div>

    }
    </Fragment>
  );
}

export default Table;